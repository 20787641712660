import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { ChargeCodeModel } from "models/Settings/RevenuManagement/ChargeCodeModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ChargeCodeModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<ChargeCodeModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getAllForItems =
  (axios: AxiosInstance) => async (): Promise<ChargeCodeModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE_ITEMS)
      .then(({ data }) => data);
  };

export const getById =
  (axios: AxiosInstance) =>
  async (id?: number): Promise<ChargeCodeModel> => {
    return await axios
      .get(`/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE}/${id}`)
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: ChargeCodeModel): Promise<ChargeCodeModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: ChargeCodeModel): Promise<ChargeCodeModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ChargeCodeModel> => {
    return await axios
      .patch(
        `/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE}/${id}`
      )
      .then(({ data }) => data);
  };
