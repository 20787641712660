interface FinancialAccountModel {
  id?: number;
  room_account: {
    number: string | number;
  };
  group: {
    id: number;
    name: string;
  };
  guest: {
    id: number;
    first_name: string;
    last_name: string;
    adult: number;
    child: number;
  };
  period: {
    from: string;
    to: string;
  };
  status: FinancialAccountStatusEnum;
  company:
    | {
        id: number;
        name: string;
      }
    | undefined;
  market: {
    id: number;
    name: string;
  };
}

export enum FinancialAccountStatusEnum {
  CHECKED_IN = "checked_in",
  CHECKED_OUT = "checked_out",
  EXPECTED = "expected",
}

export interface FinancialAccountListItemModel extends FinancialAccountModel {}

export default FinancialAccountModel;
