import { AxiosInstance } from "axios";
import { PaymentTypeService } from "services";

export interface PaymentTypeServiceModel {
  updateChargeCode: ReturnType<typeof PaymentTypeService.updateChargeCode>;
  removeChargeCode: ReturnType<typeof PaymentTypeService.removeChargeCode>;
  getChargeCodeByPaymentId: ReturnType<
    typeof PaymentTypeService.getChargeCodeByPaymentId
  >;
  getAll: ReturnType<typeof PaymentTypeService.getAll>;
  getAllChain: ReturnType<typeof PaymentTypeService.getAllChain>;
  getAllOpenBalance: ReturnType<typeof PaymentTypeService.getAllOpenBalance>;
}

export const initPaymentTypeService = (
  axios: AxiosInstance
): PaymentTypeServiceModel => ({
  updateChargeCode: PaymentTypeService.updateChargeCode(axios),
  removeChargeCode: PaymentTypeService.removeChargeCode(axios),
  getChargeCodeByPaymentId: PaymentTypeService.getChargeCodeByPaymentId(axios),
  getAll: PaymentTypeService.getAll(axios),
  getAllChain: PaymentTypeService.getAllChain(axios),
  getAllOpenBalance: PaymentTypeService.getAllOpenBalance(axios),
});
