import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  DialogProps,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { Error } from "components/Common/Error";
import {
  DialogButtonCancel,
  DialogButtonSave,
} from "components/DialognActionButtons";
import { useRootContext } from "components/RootContainer";
import { RootDialog, RootDialogTitle } from "components/RootDialog";
import { MenuItem } from "components/styled/SelectMenuItem";
import { get } from "lodash";
import FinancialAccountNumberModel from "models/Settings/Financial/FinancialAccountNumberModel";
import { FC, useId } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { parseAxiosError } from "utils/parseAxiosError";
import useSchema from "./useSchema";

type Props = DialogProps & {
  defaultValues: FinancialAccountNumberModel;
  mode?: "create" | "update";
  handleClose?: () => void;
  onSaveSuccess?: () => void;
  resolve: (
    value: FinancialAccountNumberModel
  ) => Promise<FinancialAccountNumberModel>;
  onSuccess: () => void;
};

const FinancialAccountNumberCreate: FC<Props> = ({
  defaultValues,
  handleClose,
  onSuccess,
  resolve,
  ...props
}) => {
  const schema = useSchema();

  const form = useForm<FinancialAccountNumberModel>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    HotelApi: {
      settings: {
        financial: { financialAccountGroups },
      },
    },
  } = useRootContext();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["financialAccountGroups"],
    queryFn: () => financialAccountGroups.getAll(),
  });

  const mutation = useMutation(resolve, {
    onSuccess: () => {
      onSuccess();
    },
    onError: (error: AxiosError, variables, context) => {
      const serverErrors = parseAxiosError<FinancialAccountNumberModel>(error);

      if (Object.keys(serverErrors).length > 0) {
        (
          (Object.keys(serverErrors) || []) as Array<
            keyof FinancialAccountNumberModel
          >
        ).map((fieldName) => {
          const errorMossage = get(serverErrors, fieldName, "") as string;
          form.setError(fieldName, {
            type: "required",
            message: errorMossage,
          });
          return null;
        });
      }
    },
  });

  const onSubmit = form.handleSubmit((data) => {
    mutation.mutate({ ...data, code: String(data?.code) });
  });

  const typeFieldId = useId();

  return (
    <RootDialog
      {...props}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "27rem",
        },
      }}
    >
      <RootDialogTitle
        titles={{
          subTitle: "Financial Account Number",
          title: "New Account Number",
        }}
        onClose={handleClose}
      />
      <Stack spacing="2rem" my="2rem">
        {mutation.isError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            <b>{mutation.error.code}</b> {mutation.error.message}.{" "}
          </Alert>
        )}
        <Controller
          control={form.control}
          name="code"
          render={({ field, fieldState, formState }) => (
            <TextField
              fullWidth
              label="Number"
              {...field}
              value={field.value ?? ""}
              error={fieldState.error !== undefined}
              helperText={
                fieldState.error !== undefined ? (
                  <ErrorMessage
                    errors={formState.errors}
                    name="code"
                    render={({ message }) => <Error>{message}</Error>}
                  />
                ) : null
              }
            />
          )}
        />

        <Controller
          control={form.control}
          name="description"
          render={({ field, fieldState, formState }) => (
            <TextField
              fullWidth
              label="Description"
              placeholder="Insert description"
              sx={{ mb: "1rem" }}
              {...field}
              value={field.value ?? ""}
              error={fieldState.error !== undefined}
              helperText={
                fieldState.error !== undefined ? (
                  <ErrorMessage
                    errors={formState.errors}
                    name="description"
                    render={({ message }) => <Error>{message}</Error>}
                  />
                ) : null
              }
            />
          )}
        />

        <Controller
          control={form.control}
          name="account_group_id"
          render={({ field, fieldState, formState }) => (
            <FormControl fullWidth error={fieldState.error !== undefined}>
              <InputLabel id={typeFieldId}>GROUP</InputLabel>
              <Select
                labelId={typeFieldId}
                {...field}
                value={field.value ?? ""}
              >
                <MenuItem firstItem value="">
                  <em>Select group...</em>
                </MenuItem>
                {isLoading && (
                  <MenuItem value="">
                    <Skeleton width="100%" />
                  </MenuItem>
                )}
                {isError && (
                  <MenuItem value="">
                    <Error>Error loading financial account groups</Error>
                  </MenuItem>
                )}
                {(data || []).map((item, key) => (
                  <MenuItem key={key} value={item.id}>
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error !== undefined ? (
                <ErrorMessage
                  errors={formState.errors}
                  name="account_group_id"
                  render={({ message }) => <Error>{message}</Error>}
                />
              ) : null}
            </FormControl>
          )}
        />

        <Controller
          control={form.control}
          name="is_active"
          render={({ field }) => (
            <FormControlLabel
              sx={{ m: 0 }}
              {...field}
              checked={field.value ?? false}
              onChange={(e, checked) => field.onChange(checked)}
              label={
                <Typography
                  variant="inter16"
                  fontWeight={500}
                  sx={{ ml: "1rem" }}
                >
                  Active
                </Typography>
              }
              control={<Switch />}
            />
          )}
        />
      </Stack>

      <Stack direction="row" spacing="1rem" justifyContent="space-between">
        <DialogButtonCancel onClick={handleClose}>CANCEL</DialogButtonCancel>
        <DialogButtonSave onClick={onSubmit}>SAVE</DialogButtonSave>
      </Stack>
    </RootDialog>
  );
};

export default FinancialAccountNumberCreate;
