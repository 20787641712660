import {
  Box,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import DeleteButtonWithConfirmation from "components/DeleteWidthConfirmationHoc";
import ModalLoading from "components/ModalLoading";
import { useRootContext } from "components/RootContainer";
import { TrashIcon } from "components/SvgIcons/TrashIcon";
import { useSortContext } from "hooks/useSortContext";
import FinancialAccountNumberModel from "models/Settings/Financial/FinancialAccountNumberModel";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";

type Props = {
  data?: FinancialAccountNumberModel[] | null;
  onClickEdit?: (item: FinancialAccountNumberModel) => () => void;
  queryKey: string[];
  onClickSort: (
    field: keyof FinancialAccountNumberModel,
    sortDirection: "asc" | "desc"
  ) => void;
};

const FinancialAccountNumberList: FC<Props> = ({
  data,
  onClickEdit,
  onClickSort,
  queryKey,
}) => {
  const { sortDirection, sortField } = useSortContext()!;

  const {
    HotelApi: {
      settings: {
        financial: {
          financialAccountNumbers: { remove, toggleStatus },
        },
      },
    },
  } = useRootContext();

  const queryClient = useQueryClient();

  const toggleStatusMutation = useMutation(toggleStatus, {
    onSuccess: () => {
      queryClient.refetchQueries(queryKey);
    },
  });

  const removeMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.refetchQueries(queryKey);
    },
  });

  return (
    <TableContainer component={Box}>
      <ModalLoading keepMounted open={toggleStatusMutation.isLoading} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortField === "description" ? true : false}
                onClick={() =>
                  onClickSort(
                    "description",
                    sortDirection === "asc" ? "desc" : "asc"
                  )
                }
                direction={sortField === "description" ? sortDirection : "asc"}
              >
                Description
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === "account_group_id" ? true : false}
                onClick={() =>
                  onClickSort(
                    "account_group_id",
                    sortDirection === "asc" ? "desc" : "asc"
                  )
                }
                direction={
                  sortField === "account_group_id" ? sortDirection : "asc"
                }
              >
                Group
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" />
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {(data || []).map((item, key) => (
            <TableRow
              key={key}
              role="button"
              sx={{ cursor: "pointer" }}
              onClick={onClickEdit?.(item)}
            >
              <TableCell>{item.description}</TableCell>
              <TableCell
                sx={{
                  fontWeight: "normal",
                  color: "colors.gray.o70",
                }}
              >
                {item.account_group?.description ?? "----"}
              </TableCell>
              <TableCell
                sx={{ width: "7.5rem" }}
                align="right"
                onClick={(e) => e.stopPropagation()}
              >
                <Switch
                  checked={item.is_active}
                  onClick={() => toggleStatusMutation.mutate(Number(item.id))}
                />
              </TableCell>
              <TableCell sx={{ width: "5.5rem" }} align="right">
                <DeleteButtonWithConfirmation
                  icon={<TrashIcon sx={{ color: "colors.red.o50" }} />}
                  onDelete={() => removeMutation.mutate(Number(item.id))}
                  isLoading={
                    removeMutation.isLoading &&
                    removeMutation.variables === item?.id
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FinancialAccountNumberList;
