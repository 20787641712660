import { AxiosInstance } from "axios";
import { ReservationStayCardBillService } from "services/Reservations";

export interface StayCardBillService {
  getAll: ReturnType<typeof ReservationStayCardBillService.getAll>;
  search: ReturnType<typeof ReservationStayCardBillService.search>;
  update: ReturnType<typeof ReservationStayCardBillService.update>;
  transferCharge: ReturnType<
    typeof ReservationStayCardBillService.transferCharge
  >;
  transferPayment: ReturnType<
    typeof ReservationStayCardBillService.transferPayment
  >;
  addNewBill: ReturnType<typeof ReservationStayCardBillService.addNewBill>;
  postTransaction: ReturnType<
    typeof ReservationStayCardBillService.postTransaction
  >;
  postPayment: ReturnType<typeof ReservationStayCardBillService.postPayment>;
}

export const initStayCardBillService = (
  axios: AxiosInstance
): StayCardBillService => ({
  getAll: ReservationStayCardBillService.getAll(axios),
  search: ReservationStayCardBillService.search(axios),
  update: ReservationStayCardBillService.update(axios),
  transferCharge: ReservationStayCardBillService.transferCharge(axios),
  transferPayment: ReservationStayCardBillService.transferPayment(axios),
  addNewBill: ReservationStayCardBillService.addNewBill(axios),
  postTransaction: ReservationStayCardBillService.postTransaction(axios),
  postPayment: ReservationStayCardBillService.postPayment(axios),
});
