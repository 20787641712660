import FinancialAccountModel, {
  FinancialAccountStatusEnum,
} from "models/FrontDesk/FinancialAccountModel";

const getRandomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
const getRandomDate = (start: Date, end: Date) => {
  const date = new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
  return date.toISOString().split("T")[0];
};

const tunisianFirstNames = [
  "Walid",
  "Abdelmonom",
  "Rzouga",
  "Khoubaib",
  "Mohamed",
  "Rached",
  "Reception",
  "Cartago",
];
const tunisianLastNames = [
  "Essaied",
  "Tlili",
  "Ben omar",
  "tlili",
  "Melouki",
  "ben khalifa",
  "",
  "",
];
const groups = [
  { id: 1, name: "PM" },
  { id: 2, name: "STF" },
];

const companies = [
  { id: 1, name: "Cartage Tours" },
  { id: 2, name: "Medianet" },
];

export const generateDataItem = (index: number): FinancialAccountModel => {
  const nameIndex = getRandomNumber(
    0,
    Math.min(tunisianFirstNames.length, tunisianLastNames.length) - 1
  );

  return {
    id: getRandomNumber(1, 1000),
    room_account: {
      number: `R-${getRandomNumber(100, 999)}`,
    },
    group: getRandomNumber(1, 2) === 1 ? groups[0] : groups[1],
    guest: {
      id: getRandomNumber(1, 1000),
      first_name: tunisianFirstNames[nameIndex],
      last_name: tunisianLastNames[nameIndex],
      adult: getRandomNumber(1, 2),
      child: getRandomNumber(0, 3),
    },
    period: {
      from: getRandomDate(new Date(2023, 0, 1), new Date()),
      to: getRandomDate(new Date(2023, 0, 1), new Date()),
    },
    status:
      getRandomNumber(1, 2) === 1
        ? FinancialAccountStatusEnum.CHECKED_IN
        : FinancialAccountStatusEnum.EXPECTED,
    company:
      Math.random() > 0.5
        ? companies[getRandomNumber(0, companies.length - 1)]
        : undefined,
    market: {
      id: getRandomNumber(1, 100),
      name: `Market ${getRandomNumber(1, 100)}`,
    },
  };
};

export const generateDataArray = (size: number): FinancialAccountModel[] => {
  return Array.from({ length: size }, (_, index) => generateDataItem(index));
};
