import { AxiosInstance } from "axios";
import { CashierRateExchnageServices } from "services";

export interface CashierRateExchangeServiceModel {
  update: ReturnType<typeof CashierRateExchnageServices.update>;
  create: ReturnType<typeof CashierRateExchnageServices.create>;
  remove: ReturnType<typeof CashierRateExchnageServices.remove>;
  toggleStatus: ReturnType<typeof CashierRateExchnageServices.toggleStatus>;
  getAll: ReturnType<typeof CashierRateExchnageServices.getAll>;
}

export const initCashierRateExchangeService = (
  axios: AxiosInstance
): CashierRateExchangeServiceModel => ({
  update: CashierRateExchnageServices.update(axios),
  create: CashierRateExchnageServices.create(axios),
  remove: CashierRateExchnageServices.remove(axios),
  toggleStatus: CashierRateExchnageServices.toggleStatus(axios),
  getAll: CashierRateExchnageServices.getAll(axios),
});
