import { AxiosInstance } from "axios";
import { FinancialAccountNumberService } from "services";

export interface FinancialAccountNumberServiceModel {
  getAll: ReturnType<typeof FinancialAccountNumberService.getAll>;
  create: ReturnType<typeof FinancialAccountNumberService.create>;
  update: ReturnType<typeof FinancialAccountNumberService.update>;
  remove: ReturnType<typeof FinancialAccountNumberService.remove>;
  toggleStatus: ReturnType<typeof FinancialAccountNumberService.toggleStatus>;
}

export const initFinancialAccountNumberService = (
  axios: AxiosInstance
): FinancialAccountNumberServiceModel => ({
  getAll: FinancialAccountNumberService.getAll(axios),
  create: FinancialAccountNumberService.create(axios),
  update: FinancialAccountNumberService.update(axios),
  remove: FinancialAccountNumberService.remove(axios),
  toggleStatus: FinancialAccountNumberService.toggleStatus(axios),
});
