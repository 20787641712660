import { AxiosInstance } from "axios";
import { ReservationFinancialAccountService } from "services/Reservations";

export interface ReservationFinancialAccountServiceModel {
  update: ReturnType<typeof ReservationFinancialAccountService.update>;
  create: ReturnType<typeof ReservationFinancialAccountService.create>;
  remove: ReturnType<typeof ReservationFinancialAccountService.remove>;
  toggleStatus: ReturnType<
    typeof ReservationFinancialAccountService.toggleStatus
  >;
  getAll: ReturnType<typeof ReservationFinancialAccountService.getAll>;
}

export const initReservationFinancialAccountService = (
  axios: AxiosInstance
): ReservationFinancialAccountServiceModel => ({
  update: ReservationFinancialAccountService.update(axios),
  create: ReservationFinancialAccountService.create(axios),
  remove: ReservationFinancialAccountService.remove(axios),
  toggleStatus: ReservationFinancialAccountService.toggleStatus(axios),
  getAll: ReservationFinancialAccountService.getAll(axios),
});
