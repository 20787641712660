import {
  CASH_REGISTER_VERIFY_CODE_PIN,
  CASHIER_CREATE_EXCHANHE,
  CASHIER_CREATE_OTHER_OPERATION,
  CASHIER_CREATE_OTHER_OPERATION_UPDATE,
  CASHIER_GET_ALL,
  CASHIER_GET_EXCHANGE,
  CASHIER_GET_HISTORY,
  CASHIER_GET_OTHER,
  CASHIER_GET_SUMMARY,
} from "@constants/ApiEndpoints/CashierRootEndpoint";
import { CASHIER_ROOT_ENDPOINT } from "@constants/ApiEndpoints/RootEndpoint";
import { AxiosInstance } from "axios";
import _ from "lodash";
import { CashierOpenCloseBalanceListModel } from "models/Cashier/CashierRateExchange";
import { OtherOperationModel } from "models/Cashier/OtherOperation";
import {
  ExchangeResponseModel,
  OtherModel,
  SummaryModel,
} from "models/Cashier/TransactionsModels";
import { CashRegisterModel } from "models/Settings/Financial/CashOpertationModel";
import { ExchangeOperationFormModel } from "pages/CashierModule/pages/CashierPage/models";
import { sprintf } from "sprintf-js";

export type Params = {
  search?: string;
  limit?: number;
  offset?: number;
};

export interface SharedCashierResponseModel<T> {
  balance: number;
  content: T;
}

export interface BaseCashierListResponseModel {
  exchange?: SharedCashierResponseModel<[]>;
  other?: SharedCashierResponseModel<[]>;
  open_balance?: SharedCashierResponseModel<[]>;
  summary?: SharedCashierResponseModel<[]>;
}

export interface CashierOtherListResponseModel
  extends Omit<BaseCashierListResponseModel, "other"> {
  other: SharedCashierResponseModel<OtherModel[]>;
}

export interface CashierExchangeListResponseModel
  extends Omit<BaseCashierListResponseModel, "exchange"> {
  exchange: SharedCashierResponseModel<ExchangeResponseModel[]>;
}

export interface CashierSummaryListResponseModel
  extends Omit<BaseCashierListResponseModel, "summary"> {
  summary: SharedCashierResponseModel<SummaryModel>;
}

export interface CashierSummaryHistoryModel {
  id: null;
  description: string | null;
  amount: string | null;
  tag: string;
  clientele_name: string | null;
  date_time: string;
  operation_id: number | null;
  operation_type: CashierOperationTypeEnum;
}

export enum CashierOperationTypeEnum {
  EXCHANGE = "exchange",
  OTHER = "other",
  SUBMIT = "submit",
  INPUT = "input",
}

export interface CashierOpenCloseBalanceListResponseModel
  extends Omit<BaseCashierListResponseModel, "open_balance"> {
  open_balance: SharedCashierResponseModel<CashierOpenCloseBalanceListModel>;
}

export const getCashRegisterForCurrentUser =
  (axios: AxiosInstance) =>
  async (): Promise<{
    items: CashRegisterModel[];
    total: number;
  }> =>
    await axios.get(`${CASHIER_ROOT_ENDPOINT}`).then(({ data }) => data);

export const getAllCashRegisters =
  (axios: AxiosInstance) => async (): Promise<CashRegisterModel[]> =>
    await axios.get(`${CASHIER_ROOT_ENDPOINT}`).then(({ data }) => data);
/**
 *
 */
export const getAllExchanges =
  (axios: AxiosInstance) =>
  async (params?: {
    limit?: number;
    offset?: number;
    cash_register: number;
  }): Promise<CashierExchangeListResponseModel> =>
    await axios
      .get(sprintf(CASHIER_GET_EXCHANGE, params?.cash_register), {
        params: _.omit(params, "cash_register"),
      })
      .then(({ data }) => data);

export const getOtherTransactions =
  (axios: AxiosInstance) =>
  async (params?: {
    limit?: number;
    offset?: number;
    cash_register: number;
  }): Promise<CashierOtherListResponseModel> =>
    await axios
      .get(sprintf(CASHIER_GET_OTHER, params?.cash_register), {
        params: _.omit(params, "cash_register"),
      })
      .then(({ data }) => data);

export const getHistory =
  (axios: AxiosInstance) =>
  async (params?: {
    limit?: number;
    offset?: number;
    cash_register: number;
  }): Promise<CashierSummaryHistoryModel[]> =>
    await axios
      .get(sprintf(CASHIER_GET_HISTORY, params?.cash_register), {
        params: _.omit(params, "cash_register"),
      })
      .then(({ data }) => data);

export const getSummary =
  (axios: AxiosInstance) =>
  async (params?: {
    limit?: number;
    offset?: number;
    cash_register: number;
  }): Promise<CashierSummaryListResponseModel> =>
    await axios
      .get(sprintf(CASHIER_GET_SUMMARY, params?.cash_register), {
        params: _.omit(params, "cash_register"),
      })
      .then(({ data }) => data);
/********************** */

export const update =
  (axios: AxiosInstance) =>
  async (data: Partial<OtherOperationModel>): Promise<OtherOperationModel> => {
    return await axios
      .post(CASHIER_GET_ALL, data)
      .then(({ data }) => data?.items);
  };

export const createOther =
  (axios: AxiosInstance) =>
  async (data: Partial<OtherOperationModel>): Promise<OtherOperationModel> => {
    return await axios
      .post(sprintf(CASHIER_CREATE_OTHER_OPERATION, data.cash_register_id), {
        ..._.omit(data, "cash_register_id"),
      })
      .then(({ data }) => data?.items);
  };

export const updateOther =
  (axios: AxiosInstance) =>
  async (data: Partial<OtherOperationModel>): Promise<OtherOperationModel> => {
    return await axios
      .put(
        sprintf(
          CASHIER_CREATE_OTHER_OPERATION_UPDATE,
          data.cash_register_id,
          data?.id
        ),
        {
          ..._.omit(data, "cash_register_id"),
        }
      )
      .then(({ data }) => data?.items);
  };

export const createExchange =
  (axios: AxiosInstance) =>
  async (
    data: Partial<ExchangeOperationFormModel>
  ): Promise<ExchangeOperationFormModel> => {
    return await axios
      .post(sprintf(CASHIER_CREATE_EXCHANHE, data?.cash_register_id), {
        ..._.omit(data, "cash_register_id"),
      })
      .then(({ data }) => data?.items);
  };

export const verifyCodePin =
  (axios: AxiosInstance) =>
  async (data: CashierAuthFormModel): Promise<any> => {
    return await axios
      .post(CASH_REGISTER_VERIFY_CODE_PIN, {
        ...data,
      })
      .then(({ data }) => data?.items);
  };

export interface CashierAuthFormModel {
  cash_register: number | null;
  code_pin: string | null;
  domain: "cash_register" | "stay_card";
}
