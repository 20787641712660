import { CircularProgress, IconButton } from "@mui/material";
import { withConfirmationDilog } from "components/ConfirmationDialog";
import { TrashIcon } from "components/SvgIcons/TrashIcon";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const DeleteButton: FC<{ icon?: JSX.Element }> = ({ icon }) => {
  return icon ? icon : <TrashIcon sx={{ color: "colors.gray.o70" }} />;
};

const WithConfirmationDeleteButton = withConfirmationDilog(DeleteButton);

interface DeleteButtonWithConfirmationPropsModel {
  isLoading: boolean;
  onDelete: () => void;
  icon?: JSX.Element;
}
const DeleteButtonWithConfirmation: FC<
  DeleteButtonWithConfirmationPropsModel
> = ({ isLoading, onDelete, icon }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <IconButton
      size="small"
      sx={{
        p: 0,
        width: 24,
        height: 24,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <WithConfirmationDeleteButton
          isLoading={isLoading}
          title={t("delete-confirmation")}
          content={t("are-you-sure-you-want-to-proceed")}
          onProceed={onDelete}
          onCancel={() => console.log("onCancel")}
          icon={icon}
        />
      )}
    </IconButton>
  );
};

export default DeleteButtonWithConfirmation;
