import { AxiosInstance } from "axios";
import {
  AgeRangeServiceModel,
  initAgeRangeService,
} from "../../ChainSettingServices/Reservation/AgeRange/AgeRangeService";
import { AddonServiceModel, initAddonService } from "./Addon/AddonService";
import { BoardServiceModel, initBoardService } from "./Board/BoardService";
import {
  BoardSettingsModel,
  initBoardSettings,
} from "./BoardServiceSettings/BoardServiceSettings";
import {
  ComplimentaryCategoryServiceModel,
  initComplimentaryCategoryService,
} from "./ComplimentaryCategory/ComplimentaryCategoryService";
import {
  FrequencyServiceModel,
  initFrequencyService,
} from "./Frequency/FrequencyService";
import {
  PackageServiceModel,
  initPackageService,
} from "./Package/PackageService";
import {
  RangeTemplateServiceModel,
  initRangeTemplateService,
} from "./RangeTemplate/RangeTemplateService";
import {
  RateTypeServiceModel,
  initRateTypeService,
} from "./RateType/RateTypeService";
import {
  RestrictionServiceModel,
  initRestrictionService,
} from "./Restriction/RestrictionService";
import {
  CancellationPenalityServiceModel,
  initCancellationPenalityService,
} from "./Rule/CancellationPenalityService";
import {
  DepositRequestServiceModel,
  initDepositRequestService,
} from "./Rule/DepositRequestService";
import {
  NosShowPenalityServiceModel,
  initNosShowPenalityService,
} from "./Rule/NosShowPenalityService copy";
import {
  RulesAndPromotionServiceModel,
  initRulesAndPromotionService,
} from "./RulesAndPromotion/RulesAndPromotionService";
import { SeasonServiceModel, initSeasonService } from "./Season/SeasonService";

export interface SettingRateAndPromoServiceModel {
  season: SeasonServiceModel;
  board: BoardServiceModel;
  boardServiceSettings: BoardSettingsModel;
  addon: AddonServiceModel;
  complimentaryCategory: ComplimentaryCategoryServiceModel;
  package: PackageServiceModel;
  rateType: RateTypeServiceModel;
  ageRange: AgeRangeServiceModel;
  restriction: RestrictionServiceModel;
  cancellationPenality: CancellationPenalityServiceModel;
  nosShowPenality: NosShowPenalityServiceModel;
  depositRequest: DepositRequestServiceModel;
  frequency: FrequencyServiceModel;
  rulesAndPromotion: RulesAndPromotionServiceModel;
  rangeTemplate: RangeTemplateServiceModel;
}

export const initSettingRateAndPromoService = (
  axios: AxiosInstance
): SettingRateAndPromoServiceModel => ({
  season: initSeasonService(axios),
  board: initBoardService(axios),
  boardServiceSettings: initBoardSettings(axios),
  addon: initAddonService(axios),
  complimentaryCategory: initComplimentaryCategoryService(axios),
  package: initPackageService(axios),
  rateType: initRateTypeService(axios),
  ageRange: initAgeRangeService(axios),
  restriction: initRestrictionService(axios),
  cancellationPenality: initCancellationPenalityService(axios),
  frequency: initFrequencyService(axios),
  rulesAndPromotion: initRulesAndPromotionService(axios),
  nosShowPenality: initNosShowPenalityService(axios),
  depositRequest: initDepositRequestService(axios),
  rangeTemplate: initRangeTemplateService(axios),
});
