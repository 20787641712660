import { LIMIT_ITEMS_SELECT } from "@constants/Pagination";
import { Box } from "@mui/material";
import ErrorLoadData from "components/Alerts/ErrorLoadData";
import EmptyContent from "components/EmptyContent";
import LoadingPage from "components/LoadingPage";
import { useRootContext } from "components/RootContainer";
import SortProvider, { useSortContext } from "hooks/useSortContext";
import FinancialAccountNumberModel from "models/Settings/Financial/FinancialAccountNumberModel";
import SettingListHeader from "pages/SettingsModule/components/ListHeader";
import { FC, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { v4 as uuid } from "uuid";
import FinancialAccountNumberCreate from "./components/Create";
import FinancialAccountNumberList from "./components/List";

const defaultValues: FinancialAccountNumberModel = {
  description: null,
  is_active: true,
  account_group_id: null,
  code: null,
};

type OpenCreateType = {
  key: string;
  open: boolean;
  defaultValues: FinancialAccountNumberModel;
  mode: "create" | "update";
};

const openCreateValues: OpenCreateType = {
  key: uuid(),
  open: false,
  mode: "create",
  defaultValues,
};

const FinancialAccountNumberPageComponent: FC = () => {
  const navigate = useNavigate();

  const handleClickBack = () => navigate(-1);

  const [openCreate, setOpenCreate] =
    useState<OpenCreateType>(openCreateValues);

  const handleOpenCreate = () => {
    setOpenCreate({ key: uuid(), open: true, mode: "create", defaultValues });
  };

  const handleCloseCreate = () => {
    setOpenCreate({
      key: uuid(),
      open: false,
      mode: "create",
      defaultValues,
    });
  };

  const handleOpenEdit = (defaultValues: FinancialAccountNumberModel) => () => {
    setOpenCreate({
      key: uuid(),
      open: true,
      mode: "update",
      defaultValues: {
        account_group_id: Number(defaultValues.account_group?.id),
        code: defaultValues.code,
        description: defaultValues.description,
        is_active: defaultValues.is_active,
        id: defaultValues?.id,
      },
    });
  };

  const [search, setSearch] = useState<string>("");
  const handleSearchChange = (value: string) => setSearch(value);

  const {
    HotelApi: {
      settings: {
        financial: {
          financialAccountNumbers: { create, update, getAll },
        },
      },
    },
  } = useRootContext();

  const queryIdentify = ["financial-account-numbers"];
  const queryClient = useQueryClient();

  const [searchBouncedValue] = useDebounce(search, 500);

  const { setSortFiled, sortDirection, sortField } = useSortContext()!;

  const { isLoading, isError, data } = useQuery({
    queryKey: queryIdentify,
    queryFn: () =>
      getAll({
        search: [
          {
            ...(search !== ""
              ? {
                  column: "code",
                  operator: "like",
                  value: searchBouncedValue,
                }
              : {}),
          },
        ],
        sort: { [sortField ? sortField : "code"]: sortDirection },
        offset: 0,
        limit: LIMIT_ITEMS_SELECT,
      }),
  });

  const onClickSort = (
    field: keyof FinancialAccountNumberModel,
    sortDirection: "asc" | "desc"
  ) => {
    setSortFiled(field, sortDirection);
  };

  const onSuccess = () => {
    handleCloseCreate();
    queryClient.refetchQueries(queryIdentify);
  };

  const render = () => {
    if (isLoading) {
      return <LoadingPage />;
    }
    if (isError || !data) {
      return <ErrorLoadData />;
    }
    if (data?.length === 0) {
      return <EmptyContent />;
    }
    return (
      <FinancialAccountNumberList
        data={data}
        onClickEdit={handleOpenEdit}
        queryKey={queryIdentify}
        onClickSort={onClickSort}
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <SettingListHeader
          search={search}
          searchPlaceholder="Search By Description..."
          handleSearchChange={handleSearchChange}
          handleClickBack={handleClickBack}
          handleClickAddNew={handleOpenCreate}
        />
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            overflowY: "auto",
            scrollSnapType: "y mandatory",
            p: "1rem 1.5rem 2rem",
            position: "relative",
          }}
        >
          {render()}
        </Box>
      </Box>
      <FinancialAccountNumberCreate
        {...openCreate}
        handleClose={handleCloseCreate}
        resolve={openCreate.mode === "create" ? create : update}
        onSuccess={onSuccess}
      />
    </>
  );
};

const FinancialAccountNumberPage: FC = (): JSX.Element => {
  return (
    <SortProvider
      defaultSortParms={{ sortDirection: "asc", sortField: "code" }}
    >
      <FinancialAccountNumberPageComponent />
    </SortProvider>
  );
};

export default FinancialAccountNumberPage;
