import { AxiosInstance } from "axios";
import { CashierOpenCloseServices } from "services";

export interface CashierOpenCloseServiceModel {
  getAll: ReturnType<typeof CashierOpenCloseServices.getAll>;
  createInput: ReturnType<typeof CashierOpenCloseServices.createInput>;
  createSubmit: ReturnType<typeof CashierOpenCloseServices.createSubmit>;
}

export const initCashierOpenCloseService = (
  axios: AxiosInstance
): CashierOpenCloseServiceModel => ({
  getAll: CashierOpenCloseServices.getAll(axios),
  createInput: CashierOpenCloseServices.createInput(axios),
  createSubmit: CashierOpenCloseServices.createSubmit(axios),
});
