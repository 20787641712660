import {
  Dialog,
  DialogContent,
  DialogProps,
  Fade,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { FC } from "react";
import { LoadingAnimation } from "./components/LoadingAnimation";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} timeout={500} {...props} />;
});

interface LoadingProps extends Omit<DialogProps, "onClose"> {
  title?: string;
  description?: string;
  absolute?: boolean;
}

const ModalLoading: FC<LoadingProps> = ({
  title,
  description,
  absolute,
  ...props
}): JSX.Element => (
  <Dialog
    TransitionComponent={Transition}
    PaperProps={{
      sx: {
        borderRadius: 0,
        boxShadow: "none",
      },
    }}
    {...props}
    sx={{
      ...(absolute && { position: "absolute", top: 0, left: 0 }),
    }}
  >
    <DialogContent
      sx={{
        width: "22rem",
        height: "15.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexFlow: "column",
      }}
    >
      <LoadingAnimation mb="1.5rem" />
      <Typography variant="inter32" color="colors.blueR.o90" fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="inter16" color="colors.gray.o60">
        {description}
      </Typography>
    </DialogContent>
  </Dialog>
);

ModalLoading.defaultProps = {
  title: "Saving ...",
  description: "Please wait operation in progress",
};

export default ModalLoading;
