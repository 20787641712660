import { ClientTypeEnum } from "models/Cashier/TransactionsModels";
import { CurrencyModel } from "models/Settings/Financial/CurrencyModel";
import { PaymentTypeModel } from "models/Settings/Financial/PaymentTypeModel";

export enum PaymentType {
  Cash = "Cash",
  TPE = "TPE",
  Virement = "Virement",
}

export enum RegisterOpertationType {
  OTHER_OPERATIONS = "other_operations",
  GUARANTIES = "guaranties",
  PREPAID = "prepaid",
}

export interface CashRegisterOperation {
  id: number;
  description: string;
  cash_register_operation_type_id: number;
  default_value: string;
  sens: string;
  is_active: boolean;
}

export interface CashRegisterOperationType {
  id: number;
  name: string;
  value: RegisterOpertationType;
}

export interface OtherOpertationTransactionModel {
  id: number;
  date_time: string;
  amount: string;
  currency: CurrencyModel;
  reference: string;
  quantity: number;
  cash_register_operation: CashRegisterOperation;
  cash_register_operation_type: CashRegisterOperationType;
  description: string;
  posted_by: string;
  payment_type: PaymentTypeModel;
}

export interface ExchangeModel {
  id: number;
  date_time: string;
  cash_register_id: number | string;
  details: {
    local_amount: number | string;
    amount_exchange: number | string;
    rate: number | string;
    local_currency: CurrencyModel;
    currency: {
      id: number;
      name: string;
      code: string;
      is_active: boolean;
    };
  }[];
}

export interface ExchangeOperationFormModel {
  full_name: string | null;
  passport_number: string | null;
  nationality_id: number | string | null;
  room: number;
  guest_id: number | null;
  clientele_type: ClientTypeEnum;
  cash_register_id: number;
  details: Array<ExchangeOpertationDetails>;
}

export interface ExchangeOpertationDetails {
  currency_id: number | null;
  local_currency_id?: number | null;
  local_exchange_rate?: number | null;
  amount_exchange: number | null;
  local_amount: number | null;
}

export interface TransactionLabelProps {
  icon: JSX.Element;
  label?: string;
  balance?: string;
  active: boolean;
  balanceLabel?: string;
}

export enum InputBalanceTargetEnum {
  MONEY = "money",
  EXCHANGE = "exchange",
}

export enum FlowDirectionEnum {
  INPUT = "input",
  SUBMIT = "submit",
}

export interface MoneyExchangeCommonModel {
  money: Array<{ payment_type_id: number | null; amount: number }>;
  exchange: Array<{ currency_id: number | null; amount: number }>;
}

export interface SubmitBalanceFormModel extends MoneyExchangeCommonModel {
  id?: number;
  cashier_register_id: number;
  submit_to?: number | null;
}

export interface InputBalanceModel extends MoneyExchangeCommonModel {
  id?: number;
  cashier_register_id: number;
}
