import { EndOfDayEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  EndOfDayDataListModel,
  EndOfDayResponseModel,
  EndOfDayUpdateModel,
} from "models/EndOfDay/EndOfDayModel";
import EODTask from "models/EndOfDay/TaskEnum";
import { ConfiguratrionFormModel } from "pages/RevenuManagementModule/pages/EndOfDateCheckPage/models/CheckEndOfDayModel";

export const getAll =
  (axios: AxiosInstance) => async (): Promise<EndOfDayDataListModel> => {
    return await axios
      .get(EndOfDayEndpoint.END_OF_DAY_TASKS_GET)
      .then(({ data }) => data);
  };

export const checkEndOfDay =
  (axios: AxiosInstance) =>
  async (params: { tasks: EODTask[] }): Promise<EndOfDayResponseModel> => {
    return await axios
      .get(EndOfDayEndpoint.END_OF_DAY_CHECK, { params })
      .then(({ data }) => {
        return data;
      });
  };

export const runEndOfDay =
  (axios: AxiosInstance) => async (): Promise<EndOfDayResponseModel> => {
    return await axios
      .get(EndOfDayEndpoint.END_OF_DAY_RUN)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: EndOfDayUpdateModel): Promise<EndOfDayDataListModel> => {
    return await axios
      .put(EndOfDayEndpoint.END_OF_DAY_TASKS_UPDATE, data)
      .then(({ data }) => data?.items);
  };

export const configuration =
  (axios: AxiosInstance) =>
  async (data: {
    task: EODTask;
    configuration: Array<{
      [subTaskName: string]: string | number;
      reservation_id: string | number;
    }>;
  }): Promise<ConfiguratrionFormModel> => {
    return await axios
      .post(EndOfDayEndpoint.END_OF_DAY_TASKS_CONFIGURATION, data)
      .then(({ data }) => data?.items);
  };
