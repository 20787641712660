import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import FinancialAccountNumberModel from "models/Settings/Financial/FinancialAccountNumberModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<FinancialAccountNumberModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_FINANCIAL_ACCOUNT_NUMBER}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<FinancialAccountNumberModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_FINANCIAL_ACCOUNT_NUMBER, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (
    data: FinancialAccountNumberModel
  ): Promise<FinancialAccountNumberModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_FINANCIAL_ACCOUNT_NUMBER, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: FinancialAccountNumberModel
  ): Promise<FinancialAccountNumberModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_FINANCIAL_ACCOUNT_NUMBER}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<FinancialAccountNumberModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_FINANCIAL_ACCOUNT_NUMBER}/${id}`)
      .then(({ data }) => data);
  };
