import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { BoardServiceSettingsModel } from "models/Settings/RatesAndPromo/BoardServiceSettings";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<BoardServiceSettingsModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_BOARD_SERVICE_SETTINGS_SERVICE}/${id}`,
      {
        params: {
          target: "board",
        },
      }
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<BoardServiceSettingsModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_BOARD_SERVICE_SETTINGS_SERVICE, {
        params: { ...params, target: "board" },
      })
      .then(({ data }) => data.items);
  };

export const getById =
  (axios: AxiosInstance) =>
  async (id?: number): Promise<BoardServiceSettingsModel> => {
    return await axios
      .get(
        `/${SettingsEndpoint.SETTINGS_RATES_BOARD_SERVICE_SETTINGS_SERVICE}/${id}`,
        {
          params: {
            target: "board",
          },
        }
      )
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (
    data: BoardServiceSettingsModel
  ): Promise<BoardServiceSettingsModel> => {
    return await axios
      .post(
        SettingsEndpoint.SETTINGS_RATES_BOARD_SERVICE_SETTINGS_SERVICE,
        data,
        {
          params: {
            target: "board",
          },
        }
      )
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: BoardServiceSettingsModel
  ): Promise<BoardServiceSettingsModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_RATES_BOARD_SERVICE_SETTINGS_SERVICE}/${data.id}`,
        data,
        {
          params: {
            target: "board",
          },
        }
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<BoardServiceSettingsModel> => {
    return await axios
      .patch(
        `/${SettingsEndpoint.SETTINGS_RATES_BOARD_SERVICE_SETTINGS_SERVICE}/${id}`,
        {
          params: {
            target: "board",
          },
        }
      )
      .then(({ data }) => data);
  };
