import {
  AddonCreateFormModel,
  RateAddOnsForReservationModel,
  RateAddOnsModel,
} from "models/RevenueManagement/Rate/RateAddOnsModel";
import { RatesEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    rateId: number
  ): Promise<RateAddOnsModel[] | RateAddOnsForReservationModel[]> => {
    return await axios
      .get(sprintf(RatesEndpoint.RATE_ADD_ONS_GET_ALL, rateId))
      .then(({ data }) => data.items);
  };

export const getList =
  (axios: AxiosInstance) =>
  async (rateId: number): Promise<RateAddOnsModel[]> => {
    return await axios
      .get(sprintf(RatesEndpoint.RATE_ADD_ONS_GET_LIST, rateId))
      .then(({ data }) =>
        (data.items || []).map(
          (item: { id: number; name: string; amount: number }) => ({
            addon_id: item.id,
            name: item.name,
            amount: item.amount,
            applied_on: null,
            include: true,
            is_active: true,
            quantity: 1,
          })
        )
      );
  };

export const create =
  (axios: AxiosInstance) =>
  async ({
    rateId,
    data,
  }: {
    rateId: number;
    data: RateAddOnsModel | AddonCreateFormModel;
  }): Promise<RateAddOnsModel[]> => {
    return await axios.post(
      sprintf(RatesEndpoint.RATE_ADD_ONS_CREATE, rateId),
      { ...data }
    );
  };

export const remove =
  (axios: AxiosInstance) =>
  async ({
    rateId,
    addonId,
  }: {
    rateId: number;
    addonId: number;
  }): Promise<RateAddOnsModel[]> => {
    return await axios.delete(
      sprintf(RatesEndpoint.RATE_ADD_ONS_REMOVE, rateId, addonId)
    );
  };
