import { AxiosInstance } from "axios";
import { FinancialAccountGroupService } from "services";

export interface FinancialAccountGroupsServiceModel {
  getAll: ReturnType<typeof FinancialAccountGroupService.getAll>;
  create: ReturnType<typeof FinancialAccountGroupService.create>;
  update: ReturnType<typeof FinancialAccountGroupService.update>;
  remove: ReturnType<typeof FinancialAccountGroupService.remove>;
  toggleStatus: ReturnType<typeof FinancialAccountGroupService.toggleStatus>;
}

export const initFinancialAccountGroupsService = (
  axios: AxiosInstance
): FinancialAccountGroupsServiceModel => ({
  getAll: FinancialAccountGroupService.getAll(axios),
  create: FinancialAccountGroupService.create(axios),
  update: FinancialAccountGroupService.update(axios),
  remove: FinancialAccountGroupService.remove(axios),
  toggleStatus: FinancialAccountGroupService.toggleStatus(axios),
});
