import { CASHIER_ROOT_ENDPOINT } from "./RootEndpoint";

export const CASHIER_GET_ALL = `${CASHIER_ROOT_ENDPOINT}`;
export const CASHIER_GET_SUMMARY = `${CASHIER_ROOT_ENDPOINT}/%s/summary`;
export const CASHIER_GET_OTHER = `${CASHIER_ROOT_ENDPOINT}/%s/other`;
export const CASHIER_GET_HISTORY = `${CASHIER_ROOT_ENDPOINT}/%s/history`;
export const CASHIER_GET_EXCHANGE = `${CASHIER_ROOT_ENDPOINT}/%s/exchange`;
export const CASHIER_CREATE_OTHER_OPERATION = `${CASHIER_ROOT_ENDPOINT}/%s/other`;
export const CASHIER_CREATE_OTHER_OPERATION_UPDATE = `${CASHIER_ROOT_ENDPOINT}/%s/other/%s`;
export const CASHIER_RATE_EXCHANGE = `/rate_exchange`;

export const CASHIER_CREATE_EXCHANHE = `${CASHIER_ROOT_ENDPOINT}/%s/exchange`;
export const CASHIER_EXCHANGE = `${CASHIER_ROOT_ENDPOINT}/exchange`;
export const CASH_REGISTER_VERIFY_CODE_PIN = `cashier/verify-code-pin`;

/** */
export const CASHIER_OPEN_ROOT = `${CASHIER_ROOT_ENDPOINT}/%s/open_balance`;
export const CASHIER_OPEN_CLOSE_GET_ALL = `${CASHIER_OPEN_ROOT}`;
/** */
export const CASHIER_OPEN_CLOSE_CREATE = `${CASHIER_OPEN_ROOT}/%s`;
/** */
