import { SETTINGS_RATE_BOARD_SERVICE_SETTINGS_PATH } from "@constants/Navigation/AppPaths/SettingsPaths";
import { RootContextProps } from "components/RootContainer";
import i18next from "i18next";
import { BoardServiceSettingsModel } from "models/Settings/RatesAndPromo/BoardServiceSettings";
import { RateFrequencyModel } from "models/Settings/RatesAndPromo/FrequencyModel";
import { RangeTemplateFormModel } from "models/Settings/RatesAndPromo/RangeTemplateModel";
import { PromotionAndChargeRulesModel } from "models/Settings/RatesAndPromo/RulesAndPromotionModel";
import React, { FC } from "react";
import { Outlet, RouteObject, useRouteError } from "react-router-dom";
import { Breadcrumb } from "routing/components/Breadcrumb";

const BoardServicePage = React.lazy(() => import("../pages/BoardServicePage"));
const ComplimentaryCategoryPage = React.lazy(
  () => import("../pages/ComplimentaryCategoryPage")
);
const CreateFrequencyPage = React.lazy(
  () => import("../pages/FrequencyPage/EditPage/CreateFrequency")
);
const EditFrequencyPage = React.lazy(
  () => import("../pages/FrequencyPage/EditPage/EditFrequency")
);
const ListingFrequency = React.lazy(
  () => import("../pages/FrequencyPage/ListingPage")
);
const LandingPage = React.lazy(() => import("../pages/LandingPage"));
const CreateRangeTemplate = React.lazy(
  () => import("../pages/RangeTemplatePage/EditPage/CreateRangeTemplate")
);
const EditRangeTemplatePage = React.lazy(
  () => import("../pages/RangeTemplatePage/EditPage/EditRangeTemplate")
);
const ListingRangeTemplatePage = React.lazy(
  () => import("../pages/RangeTemplatePage/ListingPage")
);
const RateTypePage = React.lazy(() => import("../pages/RateTypePage"));
const CreatePromotionChargeRulesPage = React.lazy(
  () =>
    import("../pages/RulesAndPromotion/EditPage/CreatePromotionChargeRulesPage")
);
const EditPromotionChargeRulesPage = React.lazy(
  () =>
    import("../pages/RulesAndPromotion/EditPage/EditPromotionChargeRulesPage")
);
const ListingRulesAndPromotionPage = React.lazy(
  () => import("../pages/RulesAndPromotion/ListingPage")
);
const ListingRulesRestrictionsPage = React.lazy(
  () => import("../pages/RulesRestrictionsPage/ListingPage")
);
const SeasonsPage = React.lazy(() => import("../pages/SeasonPage"));

const CreateBoardSettingsPage = React.lazy(
  () => import("../pages/BoardServiceSettings/EditPage/CreatePackage")
);
const EditBoardSettingsPage = React.lazy(
  () => import("../pages/BoardServiceSettings/EditPage/EditPackage")
);
const ListingBoardSettingsPage = React.lazy(
  () => import("../pages/BoardServiceSettings/ListingPage")
);

const SettingsRatePromoBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <>
      <h2>Error 💥</h2>
      <p>{error.message}</p>
    </>
  );
};

export const routePaths = (api: RootContextProps): RouteObject[] => [
  {
    index: true,
    element: <LandingPage />,
    errorElement: <SettingsRatePromoBoundary />,
  },
  {
    path: "range-template",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:RateAndPromoConfig.landingPage.range-template"
          )}
          path={"settings/rate-promo/range-template"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ListingRangeTemplatePage />
          </React.Suspense>
        ),
      },
      {
        path: "create-range-template",
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <CreateRangeTemplate />
          </React.Suspense>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EditRangeTemplatePage />
          </React.Suspense>
        ),
        loader: async ({ params }): Promise<RangeTemplateFormModel | null> => {
          const {
            HotelApi: {
              settings: {
                rateAndPromo: {
                  rangeTemplate: { getById },
                },
              },
            },
          } = api;
          if (params?.id) {
            return await getById(Number(params.id));
          }
          throw new Response("Not Found", { status: 404 });
        },
        handle: {
          crumb: (data: RangeTemplateFormModel) => (
            <Breadcrumb label={data?.name} path={null} />
          ),
        },
      },
    ],
  },
  {
    path: "frequency",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:RateAndPromoConfig.landingPage.frequency")}
          path={"settings/rate-promo/frequency"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ListingFrequency />
          </React.Suspense>
        ),
      },
      {
        path: "create-frequency",
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <CreateFrequencyPage />
          </React.Suspense>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EditFrequencyPage />
          </React.Suspense>
        ),
        loader: async ({ params }): Promise<RateFrequencyModel | null> => {
          const {
            HotelApi: {
              settings: {
                rateAndPromo: {
                  frequency: { getById },
                },
              },
            },
          } = api;
          if (params?.id) {
            return await getById(Number(params.id));
          }
          throw new Response("Not Found", { status: 404 });
        },
        handle: {
          crumb: (data: RateFrequencyModel) => (
            <Breadcrumb label={data?.code} path={null} />
          ),
        },
      },
    ],
  },
  {
    path: "promotion-rules",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:RateAndPromoConfig.landingPage.promotion-and-charge-rules"
          )}
          path={"settings/rate-promo/promotion-rules"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ListingRulesAndPromotionPage />
          </React.Suspense>
        ),
      },
      {
        path: "create-rules-promotion",
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <CreatePromotionChargeRulesPage />
          </React.Suspense>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EditPromotionChargeRulesPage />
          </React.Suspense>
        ),
        loader: async ({
          params,
        }): Promise<PromotionAndChargeRulesModel | null> => {
          const {
            HotelApi: {
              settings: {
                rateAndPromo: {
                  rulesAndPromotion: { getById },
                },
              },
            },
          } = api;
          if (params?.id) {
            return await getById(Number(params.id));
          }
          throw new Response("Not Found", { status: 404 });
        },
        handle: {
          crumb: (data: PromotionAndChargeRulesModel) => (
            <Breadcrumb label={data?.code} path={null} />
          ),
        },
      },
    ],
  },
  {
    path: "seasons",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:RateAndPromoConfig.landingPage.season")}
          path={"settings/rate-promo/seasons"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <SeasonsPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: "boards",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:RateAndPromoConfig.landingPage.boards")}
          path={"settings/rate-promo/boards"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <BoardServicePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: "board-service-settings",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:RateAndPromoConfig.landingPage.board-service-settings"
          )}
          path={SETTINGS_RATE_BOARD_SERVICE_SETTINGS_PATH}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ListingBoardSettingsPage />
          </React.Suspense>
        ),
      },
      {
        path: "create-board-service",
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <CreateBoardSettingsPage />
          </React.Suspense>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EditBoardSettingsPage />
          </React.Suspense>
        ),
        loader: async ({
          params,
        }): Promise<BoardServiceSettingsModel | null> => {
          const {
            HotelApi: {
              settings: {
                rateAndPromo: {
                  boardServiceSettings: { getById },
                },
              },
            },
          } = api;
          if (params?.id) {
            return await getById(Number(params.id));
          }
          throw new Response("Not Found", { status: 404 });
        },
        handle: {
          crumb: (data: BoardServiceSettingsModel) => (
            <Breadcrumb label={data?.code} path={null} />
          ),
        },
      },
    ],
  },
  {
    path: "rules-and-restrictions",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:RateAndPromoConfig.landingPage.rules_and_restrictions"
          )}
          path={"settings/rate-promo/rules-and-restrictions"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ListingRulesRestrictionsPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: "compilmentary-category",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:RateAndPromoConfig.landingPage.complimentary-category"
          )}
          path={"settings/rate-promo/compilmentary-category"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ComplimentaryCategoryPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: "rate-type",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:RateAndPromoConfig.landingPage.rate-type")}
          path={"settings/rate-promo/rate-type"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <RateTypePage />
          </React.Suspense>
        ),
      },
    ],
  },
];
