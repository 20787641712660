import { AxiosInstance } from "axios";
import { CashierRootServices } from "services";

export interface CashierOtherServiceModel {
  update: ReturnType<typeof CashierRootServices.update>;
  createOther: ReturnType<typeof CashierRootServices.createOther>;
  updateOther: ReturnType<typeof CashierRootServices.updateOther>;
}

export const initCashierOtherService = (
  axios: AxiosInstance
): CashierOtherServiceModel => ({
  update: CashierRootServices.update(axios),
  createOther: CashierRootServices.createOther(axios),
  updateOther: CashierRootServices.updateOther(axios),
});
