import { AxiosInstance } from "axios";
import { CashierBillingServices } from "services";

export interface CashierBillingServiceModel {
  update: ReturnType<typeof CashierBillingServices.update>;
  create: ReturnType<typeof CashierBillingServices.create>;
  remove: ReturnType<typeof CashierBillingServices.remove>;
  toggleStatus: ReturnType<typeof CashierBillingServices.toggleStatus>;
  getAll: ReturnType<typeof CashierBillingServices.getAll>;
}

export const initCashierBillingService = (
  axios: AxiosInstance
): CashierBillingServiceModel => ({
  update: CashierBillingServices.update(axios),
  create: CashierBillingServices.create(axios),
  remove: CashierBillingServices.remove(axios),
  toggleStatus: CashierBillingServices.toggleStatus(axios),
  getAll: CashierBillingServices.getAll(axios),
});
