import { Box, Stack, StackProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC } from "react";

const Animation = styled(Box)(({ theme: { palette } }) => ({
  display: "flex",
  flexDirection: "row",
  "@keyframes bounce": {
    "0%, 100%": {
      transform: "scale(1)",
      filter: "blur(0)",
      backgroundColor: palette.colors.yellow.o50,
    },
    "25%": {
      transform: "scale(0.75)",
      filter: "blur(0.025rem)",
      backgroundColor: palette.colors.yellow.o40,
    },
    "50%": {
      transform: "scale(0.5)",
      filter: "blur(0.025rem)",
      backgroundColor: palette.colors.yellow.o30,
    },
    "75%": {
      transform: "scale(0.25)",
      filter: "blur(0.025rem)",
      backgroundColor: palette.colors.yellow.o20,
    },
  },
}));

const AnimationItem = styled(Box)(({ theme: { palette } }) => ({
  width: "2rem",
  height: "2rem",
  borderRadius: "1rem",
  margin: "0.25rem",
  backgroundColor: palette.colors.yellow.o50,
  animation: "bounce 2s 1s linear infinite",
}));

export const LoadingAnimation: FC<StackProps> = ({ ...props }) => (
  <Stack direction="row" {...props}>
    <Animation>
      <AnimationItem sx={{ animationDelay: "10ms" }} />
      <AnimationItem sx={{ animationDelay: "100ms" }} />
      <AnimationItem sx={{ animationDelay: "200ms" }} />
      <AnimationItem sx={{ animationDelay: "400ms" }} />
    </Animation>
  </Stack>
);
