import { AxiosInstance } from "axios";
import { CashierRootServices } from "services";
import {
  CashierBillingServiceModel,
  initCashierBillingService,
} from "./CashierBillingService";
import {
  CashierOpenCloseServiceModel,
  initCashierOpenCloseService,
} from "./OpenCloseService";
import {
  CashierOtherServiceModel,
  initCashierOtherService,
} from "./OtherServices";
import {
  CashierRateExchangeServiceModel,
  initCashierRateExchangeService,
} from "./RateExchangeService";
import {
  CashierExchangeServiceModel,
  initCashierExchangeService,
} from "./TransactionServices";

export interface CashierRootServiceModel {
  getAllExchanges: ReturnType<typeof CashierRootServices.getAllExchanges>;
  getOtherTransactions: ReturnType<
    typeof CashierRootServices.getOtherTransactions
  >;
  getSummary: ReturnType<typeof CashierRootServices.getSummary>;
  getHistory: ReturnType<typeof CashierRootServices.getHistory>;
  other: CashierOtherServiceModel;
  exchange: CashierExchangeServiceModel;
  cashRegisterForCurrentUser: ReturnType<
    typeof CashierRootServices.getCashRegisterForCurrentUser
  >;
  getAllCashRegisters: ReturnType<
    typeof CashierRootServices.getAllCashRegisters
  >;
  verifyCodePin: ReturnType<typeof CashierRootServices.verifyCodePin>;
  rateExchange: CashierRateExchangeServiceModel;
  cashierBilling: CashierBillingServiceModel;
  openClose: CashierOpenCloseServiceModel;
}

export const initCashierRootService = (
  axios: AxiosInstance
): CashierRootServiceModel => ({
  getAllExchanges: CashierRootServices.getAllExchanges(axios),
  getOtherTransactions: CashierRootServices.getOtherTransactions(axios),
  getSummary: CashierRootServices.getSummary(axios),
  cashRegisterForCurrentUser:
    CashierRootServices.getCashRegisterForCurrentUser(axios),
  getAllCashRegisters: CashierRootServices.getAllCashRegisters(axios),
  verifyCodePin: CashierRootServices.verifyCodePin(axios),
  getHistory: CashierRootServices.getHistory(axios),
  rateExchange: initCashierRateExchangeService(axios),
  exchange: initCashierExchangeService(axios),
  other: initCashierOtherService(axios),
  openClose: initCashierOpenCloseService(axios),
  cashierBilling: initCashierBillingService(axios),
});
