import { CASHIER_RATE_EXCHANGE } from "@constants/ApiEndpoints/CashierRootEndpoint";
import { AxiosInstance } from "axios";
import FinancialAccountModel, {
  FinancialAccountListItemModel,
} from "models/FrontDesk/FinancialAccountModel";

import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { generateDataItem } from "pages/ReservationModule/pages/FinancialAccountListPage/fakeData";
import { sprintf } from "sprintf-js";

export type Params = {
  search?: string;
  limit?: number;
  offset?: number;
};

const generateDataArray = (size: number): FinancialAccountModel[] => {
  return Array.from({ length: size }, generateDataItem);
};
const FinancialAccountData = generateDataArray(10);

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<{ items: FinancialAccountListItemModel[]; total: number }> =>
    await axios
      .get(`${CASHIER_RATE_EXCHANGE}`, {
        params,
      })
      .then(({ data }) => {
        return {
          items: FinancialAccountData,
          total: FinancialAccountData?.length,
        };
      });

export const update =
  (axios: AxiosInstance) =>
  async (
    data: Partial<FinancialAccountModel>
  ): Promise<FinancialAccountModel> => {
    return await axios
      .put(`${CASHIER_RATE_EXCHANGE}/${data?.id}`, data)
      .then(({ data }) => data?.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (
    data: Partial<FinancialAccountModel>
  ): Promise<FinancialAccountModel> => {
    return await axios
      .post(sprintf(CASHIER_RATE_EXCHANGE), data)
      .then(({ data }) => data?.items);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<FinancialAccountModel> => {
    return await axios.delete(`${CASHIER_RATE_EXCHANGE}/${id}`);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<FinancialAccountModel> => {
    return await axios
      .patch(`${CASHIER_RATE_EXCHANGE}/${id}`)
      .then(({ data }) => data);
  };
