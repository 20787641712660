import { AxiosInstance } from "axios";
import { TaskService } from "services/EndOfDay";

export interface EndOfDayTaskServiceModel {
  getAll: ReturnType<typeof TaskService.getAll>;
  configuration: ReturnType<typeof TaskService.configuration>;
  update: ReturnType<typeof TaskService.update>;
  checkEndOfDay: ReturnType<typeof TaskService.checkEndOfDay>;
  runEndOfDay: ReturnType<typeof TaskService.runEndOfDay>;
}

export const initEndOfDayTaskService = (
  axios: AxiosInstance
): EndOfDayTaskServiceModel => ({
  getAll: TaskService.getAll(axios),
  update: TaskService.update(axios),
  checkEndOfDay: TaskService.checkEndOfDay(axios),
  runEndOfDay: TaskService.runEndOfDay(axios),
  configuration: TaskService.configuration(axios),
});
