import { CASHIER_RATE_EXCHANGE } from "@constants/ApiEndpoints/CashierRootEndpoint";
import { AxiosInstance } from "axios";
import CashierRateExchangeModel, {
  CashierRateExchangeListItemModel,
} from "models/Cashier/CashierRateExchange";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export type Params = {
  search?: string;
  limit?: number;
  offset?: number;
};

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<{ items: CashierRateExchangeListItemModel[]; total: number }> =>
    await axios
      .get(`${CASHIER_RATE_EXCHANGE}`, {
        params,
      })
      .then(({ data }) => data);

export const update =
  (axios: AxiosInstance) =>
  async (
    data: Partial<CashierRateExchangeModel>
  ): Promise<CashierRateExchangeModel> => {
    return await axios
      .put(`${CASHIER_RATE_EXCHANGE}/${data?.id}`, data)
      .then(({ data }) => data?.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (
    data: Partial<CashierRateExchangeModel>
  ): Promise<CashierRateExchangeModel> => {
    return await axios
      .post(sprintf(CASHIER_RATE_EXCHANGE), data)
      .then(({ data }) => data?.items);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashierRateExchangeModel> => {
    return await axios.delete(`${CASHIER_RATE_EXCHANGE}/${id}`);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashierRateExchangeModel> => {
    return await axios
      .patch(`${CASHIER_RATE_EXCHANGE}/${id}`)
      .then(({ data }) => data);
  };
