import _ from "lodash";
import { FC, ReactNode } from "react";
import create, { StoreApi } from "zustand";
import createContext from "zustand/context";

export interface SortI<T extends unknown> {
  sortDirection: "asc" | "desc";
  sortField?: keyof T;
  doOrder: (data: Array<T>) => Array<T>;
  setSortFiled: (field: keyof T, sortDirection: "asc" | "desc") => void;
}

export const { Provider, useStore: useSortContext } =
  createContext<StoreApi<SortI<any>>>();

interface InitValuesModel {
  sortDirection: "asc" | "desc";
  sortField: any;
}

export const createSortStore = (initValue?: InitValuesModel) =>
  create<SortI<any>>((set, get) => ({
    sortDirection: "asc",
    sortField: undefined,
    ...(initValue ? initValue : {}),
    setSortFiled: (field, sortDirection) => {
      set((state) => ({
        ...state,
        sortDirection: sortDirection,
        sortField: field,
      }));
    },
    doOrder(data) {
      const sf = get().sortField;
      const sd = get().sortDirection;
      return _.orderBy(data, sf, sd);
    },
  }));

export const useSort = create<SortI<any>>((set, get) => ({
  sortDirection: "asc",
  sortField: undefined,
  setSortFiled: (field, sortDirection) => {
    set((state) => ({
      ...state,
      sortDirection: sortDirection,
      sortField: field,
    }));
  },
  doOrder(data) {
    const sf = get().sortField;
    const sd = get().sortDirection;
    return _.orderBy(data, sf, sd);
  },
}));

export const SortProvider: FC<{
  children: ReactNode;
  defaultSortParms: InitValuesModel;
}> = ({ children, defaultSortParms }): JSX.Element => {
  return (
    <Provider createStore={() => createSortStore(defaultSortParms)}>
      {children}
    </Provider>
  );
};

export default SortProvider;
