import { AxiosError } from "axios";
import { get } from "lodash";

export function parseAxiosError<Type>(
  error: AxiosError
): Record<keyof Type, string> | {} {
  const errors = get(error, "response.data.errors", {}) as Record<
    string,
    string[]
  >;

  if (Object.keys(errors).length) {
    return (Object.keys(errors) || []).reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: errors[cur][0],
      };
    }, {} as Record<keyof Type, string>);
  }
  return {};
}
